.calendly {

  &__overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.8);
    z-index: 100;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    pointer-events: none;
    left: 100%;

    &.active {
      left: 0;
      opacity: 1;
      pointer-events: auto;
      transition: opacity .3s ease-in-out;
    }
  }

  &__widget {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 600px;
    background-color: $white;
    z-index: 101;
    border-radius: 5px;

    @include breakpoint(tablet) {
      width: calc(100% - 5rem);
    }

    &__close {
      position: absolute;
      top: -.5rem;
      right: -50px;
      border: none;
      background-color: transparent;
      padding: 0;

      &:hover {
        cursor: pointer;
      }

      svg {
        color: $white;
        width: 2rem;
        height: auto;
      }

      @include breakpoint(tablet) {
        right: -2.25rem;
      }
    }
  }
}

body {
  overflow: scroll;

  &.scroll-lock {
    overflow: hidden;
  }
}
