@keyframes visa {
  0% {
    z-index: 0;
  }
  5% {
    transform: translate(-10px, -28px);
    z-index: 0;
  }
  10% {
    z-index: 1;
  }
  25% {
    transform: translate(0, 0);
    z-index: 1;
  }
  35% {
    transform: translate(-10px, -28px);
    z-index: 1;
  }
  45% {
    z-index: 0;
  }
  50% {
    transform: translate(0, 0);
    z-index: 0;
  }
}

@keyframes mastercard {
  0% {
    z-index: 1;
  }
  5% {
    transform: translate(10px, 28px);
    z-index: 1;
  }
  10% {
    z-index: 0;
  }
  25% {
    transform: translate(0, 0);
    z-index: 0;
  }
  35% {
    transform: translate(10px, 28px);
    z-index: 0;
  }
  40% {
    z-index: 1;
  }
  50% {
    transform: translate(0, 0);
    z-index: 1;
  }
}

@keyframes logos {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideIn {
  60% {
    transform: translateY(40%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-40%);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    transform: scale(1.15);
    display: none;
    opacity: 0;
  }
  to {
    transform: scale(1);
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    transform: rotate(0) translateX(0);
    display: block;
    opacity: 1;
  }
  to {
    transform: rotate(-5deg) translateX(-20px);
    display: block;
    opacity: 0;
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
