.api {
  background-color: $black;
  color: $white;
  position: relative;
  overflow: hidden;

  &__content {
    margin-bottom: 6rem;
    position: relative;
    padding: 6rem 0 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    &__text {
      flex-basis: 50%;

      h4 {
        color: $purple;
      }

      p {
        max-width: 450px;
        margin-bottom: 2rem;
        color: $metal;
      }
    }

    &__image {
      position: relative;
      height: 260px;
      border-radius: 10px;
      overflow-x: scroll;
      overflow-y: hidden;
      box-shadow: 1px 5px 11px 7px $black;
      z-index: 1;

      img {
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      @include breakpoint(tablet) {
        width: 100%;
        height: 240px;

        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: flex-start;
      padding: 4rem 0;
      margin-bottom: 2rem;
      gap: 4rem;
    }
  }

  svg {
    width: 1200px;
    position: absolute;
    top: -100%;
    right: 0;
  }
}
