.partners {
  background-color: $black;
  color: $white;
  position: relative;
  overflow: hidden;

  > svg {
    width: 1200px;
    height: 1200px;
    position: absolute;
    top: -500px;
    left: 50%;
    transform: translateX(-100%);
  }

  &__content {
    position: relative;
    padding-top: 7rem;
  }

  &__main {
    position: relative;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4rem;

    &__description {
      max-width: 450px;
    }

    &__ncpa {
      margin-top: 2rem;
      height: 50px;
      width: auto;
    }

    h4 {
      color: $metal;
    }

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__video {

    &-wrapper {
      position: relative;

      video {
        border-radius: 5px;
        width: 560px;
        height: auto;

        @include breakpoint(mobile) {
          width: 100%;
        }
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.hidden {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0.2;
      }

      .button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        z-index: 100;

        &:hover {
          cursor: pointer;
        }

        svg {
          width: 100px;
          height: auto;
          color: $white;
        }
      }
    }
  }

  &__testimonial {
    border-radius: 5px;
    padding: 1rem;
    background-color: $gray;
    box-sizing: border-box;
    color: $white;

    &__navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin: 3rem auto;
      max-width: 300px;

      > button {
        background-color: transparent;
        color: $white;
        padding: 0;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
          transform: rotate(180deg);
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin: 0 2rem;

      > button {
        font-size: 0;
        margin: 0;
        padding: 0;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        outline: none;
        border: none;
        background-color: $white;
        cursor: pointer;
        transition: transform 200ms ease, background-color 300ms ease;

        &.active {
          background-color: $yellow;
          transform: scale(1.3);
        }
      }
    }

    &__client {
      font-weight: 700;
      margin: 0;
      padding-bottom: .2rem;
    }

    &__pharmacy {
      font-size: .875rem;
      color: $metal;
      margin: 0;
    }

    svg {
      width: 50px;
      height: 50px;
      color: $yellow;
    }
  }

  &__logos {
    position: relative;
    width: 100%;
    margin: 3rem 0;
    display: flex;

    img {
      height: 50px;
      width: auto;
      filter: invert(1);
      animation: logos 180s linear infinite;
    }
  }
}

