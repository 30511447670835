.button {
  background-color: $yellow;
  color: $black;
  border: none;
  border-radius: 3px;
  padding: 10px 14px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 1rem;
  width: fit-content;

  &:hover {
    cursor: pointer;
    filter: brightness(.9);
    transition: filter .2s ease-in-out;
  }

  &--secondary {
    background-color: $blue;
    color: $white;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
