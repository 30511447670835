.legal {
  background-color: $white;
  padding: 4rem 0 6rem;

  table {
    border-collapse: collapse;

    td,
    th {
      border: 1px solid $black;
      padding: .5rem 1rem;
    }
  }

  a {
    color: $black;
    text-decoration: underline;
  }
}
