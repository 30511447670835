.footer {
  background-color: $gray;
  padding: 4rem 0 1rem;
  color: $metal;

  &__demo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $cloud;

    h3 {
      color: $white;
      margin-bottom: 1rem;
    }

    p {
      font-size: .875rem;
    }

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__menu {
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    border-bottom: 1px solid $cloud;

    p {
      margin-bottom: .5rem;
    }

    &__item {

      &:first-child {
        flex: 1;
      }

      h5,
      strong {
        color: $white;
      }

      li {
        margin-bottom: 1rem;
      }

      a {
        display: flex;
        gap: 7px;
        align-items: center;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__rights {
    font-size: .75rem;
    margin: 3rem 0;
  }
}
