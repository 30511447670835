$white: #f1f1f1;
$black: #13171f;

$gray: #1c1f27;
$cloud: #323444;
$silver: #545454;
$metal: #adadad;
$fog: #c4c4c4;

$blue: #0e4afd;
$purple: #668dfe;

$honey: #ffc600;
$yellow: #ffdb4b;
