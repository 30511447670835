.stats {
  background-color: $white;
  color: $black;
  position: relative;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;

    svg {
      height: 130px;
      width: auto;
      margin-right: 2rem;

      @include breakpoint(tablet) {
        display: none;
      }
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content {
    position: relative;
    padding: 6rem 0;

    h2 {
      margin-bottom: 4rem;
    }
  }

  &__main {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    position: relative;
    align-items: center;

    h1 {
      margin: 0;
    }

    p {
      font-size: 1.125rem;
      margin-top: .5rem;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
  }

  &__item {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-color: $black;
      top: 1rem;
      left: -20px;
      width: 2px;
      height: 3.5rem;
      z-index: 100;

      @include breakpoint(mobile) {
        height: 2.75rem;
        top: .75rem;
      }
    }
  }
}
