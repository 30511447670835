$media-queries: (
  laptop: 1450px,
  tablet: 900px,
  mobile: 640px,
);

@mixin breakpoint($point, $type: max) {
  @if map-has-key($media-queries, $point) {
    @media screen and (#{$type}-width: #{map-get($media-queries, $point)}) {
      @content;
    }
  } @else {
    @error 'There is not value that could be retrieved from "#{$point}". '
        + 'Please make sure that "#{$point}" is defined in $media-queries map.';
  }
}
