@import "mixins/breakpoint";

@import "base/reset";
@import "base/variables";
@import "base/typography";
@import "base/keyframes";


@import "components/home/benefits";
@import "components/home/compliance";
@import "components/home/hero";
@import "components/home/integrations";
@import "components/home/partners";
@import "components/home/payment";
@import "components/home/pharmacies";
@import "components/home/small-section";
@import "components/home/sponsors";
@import "components/home/stats";

@import "components/solutions/checkout";
@import "components/solutions/hero";
@import "components/solutions/integration";
@import "components/solutions/invoicing";
@import "components/solutions/terminal";

@import "components/api";
@import "components/button";
@import "components/calendly";
@import "components/guide";
@import "components/legal";

@import "components/announcement";

@import "layout/footer";
@import "layout/header";

@import "utils/container";
