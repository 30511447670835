html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, ol, ul {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
}

ol, ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
