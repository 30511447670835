.benefits {
  background-color: $white;
  color: $black;

  &__content {
    position: relative;
    padding: 6rem 0 7rem;
  }

  &__main {
    display: flex;
    gap: 5rem;
    align-items: center;
    margin-bottom: 2rem;

    h4 {
      color: $silver;
    }

    p {
      max-width: 500px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      > * {
        flex-basis: 50%;
      }

      @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
      }

      svg {
        width: 100%;
      }
    }
  }

  &__items {
    margin-top: 4rem;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: flex-start;

    @include breakpoint(mobile) {
      flex-direction: column;
      margin-top: 2rem;
    }
  }

  &__item {
    flex: 1;

    &__header {
      display: flex;
      gap: .6rem;

      svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: $honey;
      }

      h5 {
        margin: 0;
      }
    }

    > p {
      font-size: .9375rem;
      color: $silver;
      margin: 0;
    }
  }
}
