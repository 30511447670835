.sponsors {
  position: relative;
  color: $fog;
  background-color: $gray;

  &__content {
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    text-align: center;
  }

  &__logos {
    flex: 1;

    &__row {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      &:first-child {
        margin-bottom: 2rem;
      }

      @include breakpoint(tablet) {
        justify-content: center;
        gap: 2rem;
      }

      img {
        height: 58px;
        filter: invert(1);
      }
    }

    @include breakpoint(tablet) {
      justify-content: center;
      gap: 2rem;
    }
  }
}
