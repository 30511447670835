.solutions-checkout {
  position: relative;
  background-color: $white;
  color: $black;

  p {
    max-width: 450px;
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 0;

    @include breakpoint(tablet) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__phone {
    position: relative;
    height: 600px;
    width: 400px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 600px;
      width: auto;

      @include breakpoint(tablet) {
        left: 17%;
      }
    }

    @include breakpoint(tablet) {
      height: 600px;
      width: 100vw;
    }
  }

  &__text {
    margin-bottom: 4rem;
  }
}
