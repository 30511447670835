.solutions-integration {
  background-color: $white;
  color: $black;

  &__content {
    position: relative;
    padding: 6rem 0;
  }

  &__main {
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;

    h4 {
      color: $silver;
    }

    p {
      max-width: 500px;
    }

    @include breakpoint(tablet) {
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }
  }

  &__logos {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    column-gap: 8rem;

    img {
      align-self: center;
      width: 130px;
      height: auto;
    }

    @include breakpoint(tablet) {
      gap: 2rem;
      column-gap: 5rem;
    }
  }
}
