.guides {
  position: absolute;
  height: 100%;
  width: calc(100% + 2.5rem);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  display: flex;
  justify-content: space-between;

  &__guide {
    height: 100%;
    width: 1px;
    background: linear-gradient(180deg, $fog, $fog 40%, transparent 0, transparent);
    background-size: 1px 10px;

    &--dark {
      background: linear-gradient(180deg, $cloud, $cloud 40%, transparent 0, transparent);
      background-size: 1px 10px;
    }

    &--yellow {
      background: linear-gradient(180deg, #deae00, #deae00 40%, transparent 0, transparent);
      background-size: 1px 10px;
    }
  }

  @include breakpoint(tablet) {
    display: none;
  }
}
