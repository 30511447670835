.compliance {
  position: relative;
  color: $fog;
  background-color: $gray;

  &__content {
    padding: 4rem 0;
    display: flex;
    gap: 3rem;
    position: relative;
    align-items: center;

    h4 {
      margin: 0;
    }

    @include breakpoint(mobile) {
      text-align: center;
      flex-direction: column;
    }
  }

  &__logos {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;

    img {
      height: 75px;
      filter: invert(1);
    }

    @include breakpoint(tablet) {
      gap: 5rem;
    }
  }
}
