.header {
  color: $white;
  padding: 2rem 0;
  position: relative;
  z-index: 10;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      display: flex;
      align-items: center;
    }
  }

  &__menu {

    @include breakpoint(mobile) {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      pointer-events: none;
      display: flex;
      align-items: center;
      gap: 2.5rem;
    }

    &__button {
      background-color: transparent;
      border: none;
      display: none;
      padding: 0;

      > svg {
        color: $white;
        width: 2rem;
        height: 2rem;
      }

      &--close {
        display: none;
      }

      @include breakpoint(mobile) {
        display: block;
        cursor: pointer;

        &.header__menu__button--close {
          display: none;
        }
      }
    }

    &__content {
      opacity: 1;
      display: flex;
      align-items: center;
      gap: 2.5rem;

      @include breakpoint(mobile) {
        transform: translateY(15%);
        opacity: 0;
        flex-direction: column;

        > button {
          font-size: 1.75rem;
        }
      }
    }

    &.active {
      pointer-events: all;

      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        color: $white;
        z-index: 2;
        padding: 7rem 0;
        font-size: 1.75rem;
        opacity: 1;
        transition: opacity .5s cubic-bezier(0.19, 1.06, 0.2, 1);
      }

      .header__menu__content {
        opacity: 1;
        transform: translateY(0);
        transition: transform 1.5s cubic-bezier(0.19, 1.06, 0.2, 1), opacity 1.5s cubic-bezier(0.19, 1.06, 0.2, 1);
      }

      .header__menu__button--close {
        display: none;
        position: absolute;
        top: 2rem;
        right: 2rem;
        padding: 0;

        @include breakpoint(mobile) {
          display: block;
        }
      }
    }
  }

  > svg {
    width: 150px;
    height: 100%;
  }
}
