.solutions-terminal {
  background-color: $white;
  color: $black;

  &__content {
    position: relative;
    padding: 6rem 0;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;

    > * {
      flex-basis: 50%;
    }

    h4 {
      color: $silver;
    }

    p {
      max-width: 500px;
    }

    @include breakpoint(tablet) {
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }
  }

  &__images {
    position: relative;

    &__dashboard {
      position: absolute;
      top: 50%;
      transform: translateY(-60%);
      left: 0;
      width: 800px;
      z-index: 100;
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }

  ul {
    margin-top: 3rem;

    li {
      display: flex;
      gap: .5rem;
      margin-bottom: 1rem;

      svg {
        width: 20px;
        height: 20px;
        color: $honey;
      }
    }
  }
}
