.payment {
  background-color: $white;
  color: $black;

  &__content {
    position: relative;
    padding: 6rem 0;
  }

  &__main {
    display: flex;
    gap: 4rem;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;

    h4 {
      color: $silver;
    }

    img {
      height: 160px;
      width: auto;
    }

    p {
      max-width: 500px;
    }

    &__mastercard {
      position: absolute;
      top: 110px;
      right: 50px;
      animation: mastercard 15s infinite;

      @include breakpoint(tablet) {
        display: none;
      }
    }

    &__visa {
      position: absolute;
      top: 0;
      right: 120px;
      animation: visa 15s infinite;

      @include breakpoint(tablet) {
        display: none;
      }
    }
  }

  &__logos {
    display: flex;
    gap: 1rem;
    margin-top: 3rem;
    flex-wrap: wrap;

    svg {
      height: 24px;
      width: auto;

      @include breakpoint(tablet) {
        height: 18px;
      }
    }
  }
}
