.pharmacies {
  background-color: $white;
  color: $black;
  position: relative;
  overflow: hidden;

  &__content {
    position: relative;
    padding: 6rem 0;
    text-align: center;

    > h2 {
      margin-bottom: 5rem;
    }

    p {
      color: $silver;
    }
  }

  &__main {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    position: relative;
    align-items: center;

    svg {
      width: 2rem;
      height: auto;
      margin-bottom: .5rem;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
  }
}
