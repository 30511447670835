html,
body {
  color: $black;
  background-color: $black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-weight: 700;

  span {
    color: $yellow;
  }
}

h3,
h4,
h5 {
  font-weight: 500;
  margin-bottom: 1rem;
}

h1 {
  font-size: 4.5rem;

  @include breakpoint(mobile) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2.5rem;

  @include breakpoint(mobile) {
    font-size: 2.25rem;
  }
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 0.9375rem;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

strong {
  font-weight: 500;
}

p,
h3,
h4,
h5 {
  line-height: 1.4;
}

::selection{
  background-color: $purple;
}
