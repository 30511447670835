.small-section {
  position: relative;
  color: $fog;
  background-color: $gray;

  &__content {
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    text-align: center;
  }

  &__logos {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: .75rem;

    img {
      height: 40px;
      filter: invert(1);
    }

    &--large {
      justify-content: space-around;

      img {
        height: 60px;
        filter: invert(1);
      }
    }

    &--xlarge {
    justify-content: center;
    gap: 9rem;

      img {
        height: 85px;
        filter: invert(1);
      }
    }

    @include breakpoint(tablet) {
      justify-content: center;
      gap: 2rem;
    }
  }
}
