.announcement {
  position: sticky;
  top: 0;
  background: $yellow;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  z-index: 100;

  &.bottom {
    position: fixed;
    bottom: 0;
    top: auto;
  }
}
