.integrations {
  background-color: $black;
  color: $white;
  position: relative;
  overflow: hidden;

  p {
    max-width: 400px;
    color: $fog;
  }

  &__animation {
    margin: 4rem auto 0;
    width: 90%;
    height: auto;
    position: relative;

    @include breakpoint(tablet) {
      display: none;
    }

    img {
      position: absolute;
      width: 11.2%;
      height: auto;
      top: 42%;
      right: .8%;

      @include breakpoint(tablet) {
        display: none;
      }
    }

    &--mobile {
      margin: 4rem auto 0;
      width: 70%;
      height: auto;
      position: relative;
      display: none;

      @include breakpoint(tablet) {
        display: block;
        width: 40%;
      }

      @include breakpoint(mobile) {
        width: 70%;
      }

      img {
        position: absolute;
        display: none;
        width: 47.2%;
        height: auto;
        bottom: 4.2%;
        right: 26%;

        @include breakpoint(tablet) {
          display: block;
        }
      }
    }
  }

  &__content {
    position: relative;
  }

  &__main {
    position: relative;
    padding: 6rem 0;

    h2 {
      margin: 0;
    }

    h4 {
      color: $metal;
    }
  }
}

