.hero {
  background-color: $black;
  color: $white;
  position: relative;
  overflow: hidden;

  > .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  h1 {
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.125rem;
    margin-bottom: 3rem;
    max-width: 450px;
    color: $fog;
  }

  &__content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6rem;

    &__main {
      display: flex;
      padding: 4rem 0;

      @include breakpoint(tablet) {
        flex-direction: column;
      }
    }

    &__text {
      flex-basis: 60%;
    }

    .guides {
      width: calc(100% + 2.5rem);
      position: absolute;
      bottom: 0;
      height: 7rem;
      top: auto;
    }
  }

  &__images {
    position: relative;
    z-index: 1;

    &__phone {
      position: absolute;
      top: 20px;
      left: 40px;
      height: 70vh;
      max-height: 550px;
      min-height: 450px;
      z-index: 1;
    }

    &__dashboard {
      position: absolute;
      top: -50px;
      left: 140px;
      height: 70vh;
      max-height: 480px;
      min-height: 400px;
      width: auto;

      @include breakpoint(tablet) {
        display: none;
      }
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    --gradient-color-1: #353748;
    --gradient-color-2: #10131c;
    --gradient-color-3: #1C1F27;
    --gradient-color-4: #13171f;
  }
}
